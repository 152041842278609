<template>
	<el-dialog title="选择上级" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="20%">
		<div class="myClient-top-10">
			<el-tree :load="loadNode" :key="Math.random()" lazy :props="defaultProps" @node-click="handleNodeClick"
				:highlight-current='true'>
			</el-tree>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				dialogVisible: false,
				groupNmae: "",
				SuperiorData: {},
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				organizationList: [],
				listQuery: {
					ParentId: -1,
					type: 3,
				},
				node_had:{
					childNodes:[]
				},
				
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			},
		},
		created() {
			this.dialogVisible = this.Visible;
			
		},
		methods: {
			close() {
				this.$emit("closepop");
			},
			loadNode(node, resolve) {
				if (node.level > 0 && !node.data.issubordinate) {
					return resolve([]);
				}
				if (node.level === 0) {
					this.listQuery.ParentId = -1;
					this.listQuery.type = 3
				}

				if (node.level > 0 && node.data.issubordinate) {
					this.listQuery.ParentId = node.data.id;
					this.listQuery.type = 3;
				}
				this.OrganizationList(this.listQuery).then(res => {
					resolve(res);
				});
			},
		
			OrganizationList(data) {
				return new Promise((resolve, reject) => {
					this.API.OrganizationGetTree(data).then(res => {
						
						resolve(res || []);
					})
				});
			},
			handleNodeClick(data) {
				this.SuperiorData = data
				this.$emit("superiorData", this.SuperiorData)
				this.$emit("closepop");
			},
		},
	};
</script>

<style>
</style>
