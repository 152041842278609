<template>
	<!-- 下级的添加和编辑 -->
	<el-dialog :title="detail.title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="40%">
		<div class="myClient-top-10 padding">

			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="上级" prop="superior">
					<el-col :span="12">
						<div @click='editHandle' class="border-gray border-r" style="height: 40px;">
							{{meaagesNames}}
							<chooseSuperior :Visible="editVisible" @closepop="closeEditPop" v-if='detail.num==3'
								@superiorData="superiorData">
							</chooseSuperior>
						</div>
					</el-col>
				</el-form-item>
				<el-form-item label="类型">
					<el-radio  v-model="ruleForm.type" :label="3">销售团队</el-radio>
					<!-- <el-radio  v-model="ruleForm.type" :label="4">销售人员</el-radio> -->
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model="ruleForm.name" maxlength="10" placeholder="最多十个字" />
				</el-form-item>
				<el-form-item label="电话1">
					<el-input v-model="ruleForm.phoneone " />
				</el-form-item>
				<el-form-item label="电话2">
					<el-input v-model="ruleForm.phonetwo " />
				</el-form-item>
			</el-form>
		</div>
		<div class="flex justify-content-center">
			<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>

			<el-button class="myClient-left-30" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import chooseSuperior from '../components/chooseSuperior'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			detail:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		components: {
			chooseSuperior
		},
		data() {
			return {
				dialogVisible: false,
				meaagesNames: '',
				duperiorDate: {},
				ruleForm: {
					ParentId: 0,
					name: "",
					phoneone: "",
					phonetwo: "",
					type: 3,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
				},
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				if (this.detail.num == 3) {
					this.ruleForm={};
					this.meaagesNames="戴氏精品堂";
					this.SuperiorMessage(this.detail.Message.ParentId)
					this.ruleForm.id = this.detail.Message.id
					this.ruleForm.name = this.detail.Message.name
					this.ruleForm.type = this.detail.Message.type
				}else {
					this.ruleForm={};
					this.ruleForm.type=3;
					this.meaagesNames=this.detail.Message.name
					this.ruleForm.ParentId = this.detail.Message.id
				}
			},
			
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit("closepop");
			},
			SuperiorMessage(ParentId) {
				this.API.OrganizationGetbyid(ParentId).then(res => {
					this.meaagesNames = res.name
					this.ruleForm.ParentId = res.id
				})
			},
			superiorData(val) {
				this.meaagesNames = val.name
				this.ruleForm.ParentId = val.id
			},
			submitForm(formName) {
				console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.API.OrganizationCreateorupdate(this.ruleForm).then(res => {
							this.$message.success("操作成功")
							this.$emit("close");
						})
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style>
	.text-align {
		text-align: center;
	}

	.active {
		background-color: #f5f5f5;
	}

	.height {
		height: 40px;
	}

	.status-point {
		background-color: #409eff;
		margin-right: 5px;
		display: inline-block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}
</style>
