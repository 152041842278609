<template>
	<div class="total">
		<div class="configure">
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="销售团队" name="first">
					<div class="margin-tb">

						<!-- 销售团队的添加 -->
						<el-button type="primary" @click="editHandle(2,{},'新增')">
							新建销售团队
						</el-button>
					</div>

					<el-table :key="Math.random()" :data="organizationList" style="width: 100%;margin-top: 20px;"
						:row-key="(row)=>{return row.guid || (row.type || 'user')+row.id}" border lazy :load="load"
						:tree-props="{hasChildren: 'issubordinate'}" v-loading="loading">

						<el-table-column label="名称" width="180">
							<template slot-scope="scope">
								<span class="lesson-tag" v-if="scope.row.type==3">团</span>
								<span class="Staff-tag" v-else>员</span>
								<span class="margin-left-xs">{{scope.row.name || scope.row.full_name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="phoneone" label="电话1" width="180">
						</el-table-column>
						<el-table-column prop="phonetwo" label="电话2" width="180">
						</el-table-column>
						<el-table-column prop="path" label="地址"> </el-table-column>
						<el-table-column align="center" label="操作">
							<template slot-scope="{row,$index}">

								<!-- 添加下级 -->
								<span @click="Popup(row,'新增',1)" class="text-blue cursor" v-if="row.type==3">添加</span>

								<!-- 销售的编辑 -->
								<span class="text-blue cursor margin-left" @click="editHandle(2,row,'编辑')"
									v-if="row.ParentId==-1">编辑</span>

								<!-- 下级的编辑 -->

								<span class="text-blue cursor margin-left" @click="Popup(row,'编辑',3)" v-else>编辑</span>

								<!-- 下级的删除 -->
								<span class="text-blue cursor margin-left" v-if="row.type==3"
									@click="Delete(row.id,$index)">删除</span>
								<span class="text-blue cursor margin-left" v-else
									@click="DeleteStaff(row,$index)">删除</span>
									
								<span @click="changeEmployees(row)" class="text-blue cursor margin-left" v-if="row.type==3">添加人员</span>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<el-pagination background layout="prev, pager, next" :total="datacount"
				style="text-align: center;margin-top: 10px;" @next-click="prevClick" @prev-click="prevClick"
				@current-change="prevClick"></el-pagination>

		</div>

		<!-- 下级的编辑和添加 -->
		<SalesTeamEditor :Visible="editVisible" :detail="TeamEditorDetail" @closepop="closeEditPop" @close="Close">
		</SalesTeamEditor>
		<!-- 销售团队的编辑和添加 -->
		<SalesTeamEditorAdd :Visible="editVisibles" :detail="TeamEditorDetailAdd" @closepop="Close"></SalesTeamEditorAdd>

		<el-dialog title="删除" :visible.sync="delorganization" :append-to-body="true" :close-on-click-modal="false"
			:close-on-press-escape="false" width="20%">
			<div class=" padding text-center">
				<span>确认删除该销售团队？</span>
			</div>
			<div class="flex justify-content-center">
				<el-button class="myClient-left-30" @click="delorganization = !delorganization">取消</el-button>
				<el-button type="primary" @click="changes">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import SalesTeamEditor from "../components/SalesTeamEditor.vue";
	import SalesTeamEditorAdd from "../components/SalesTeamEditorAdd.vue";
	export default {
		components: {
			SalesTeamEditor,
			SalesTeamEditorAdd
		},
		data() {
			return {
				datacount: 0,
				loading: true,
				editVisible: false,
				editVisibles: false,
				editType: 1,
				ids: '',
				index: '',

				TeamEditorDetail: { //传给下级添加和编辑的
					num: '',
					title: '',
					Message: '',
				},
				TeamEditorDetailAdd: { //传给销售添加和编辑的
					num: '',
					title: '',
					Message: '',
				},


				activeName: 'first',

				delorganization: false,
				organizationList: [], //组织列表
				listQuery: {
					ParentId: -1,
					type: 3,
				},
				StaffQuery: {
					org_ids: {
						ids: []
					},
				}
			};
		},

		created() {
			this.OrganizationList()
		},
		methods: {
			OrganizationList() {
				this.organizationList = []
				this.API.OrganizationGetTree(this.listQuery).then(res => {
					this.loading = false
					this.datacount = res.length
					this.organizationList = res
					this.organizationList.forEach(item => {
						if (item.issubordinate) {
							item.hasChildren = true
						}
					})
				})
			},
			//获取团队下的人员
			GetOrgUsers(id) {
				return new Promise((resolve, reject) => {
					let list = {}
					list.orgid = id
					this.API.OrganizationGetOrgUsers(list).then(res => {
						let list=res.data || [];
						if(list && list.length){
							list=list.map(t=>{
								return {
									...t,
									guid:(new Date()).getTime()+Math.random()
								}
							})
						}
						resolve(list)
					})
				})
			},
			//下级添加编辑
			Popup(detail, name, type) {
				this.editVisible = true;
				if (detail != '') {
					this.TeamEditorDetail.Message = detail;
				}
				this.TeamEditorDetail.title = name
				this.TeamEditorDetail.num = type
			},

			//销售
			editHandle(type, detail, name) {
				this.editVisibles = true;

				if (detail != '') {
					this.TeamEditorDetailAdd.Message = detail;
					console.log(detail)
				}
				this.TeamEditorDetailAdd.title = name
				this.TeamEditorDetailAdd.num = type
			},
			Delete(id, index) {
				this.ids = id;
				this.index = index;
				this.delorganization = true;
			},
			Close() {
				this.editVisibles = false;
				this.editVisible = false;
				this.OrganizationList()
			},
			changes() {
				this.API.OrganizationGetChangestatus(this.ids).then(res => {
					this.$message.success("删除成功")
					this.delorganization = false
					this.OrganizationList()
				})
			},
			load(tree, treeNode, resolve) {
				setTimeout(() => {
					let changetype = {}
					changetype.ParentId = tree.id
					changetype.type = 3
					let resultList = [];
					let getTeam = new Promise((resolve1, reject) => {
						this.API.OrganizationGetTree(changetype).then(list => {
							resultList = resultList.concat(list);
							Promise.all(list.filter(t=>t.type === 3).map(item => {
								return this.GetOrgUsers(item.id).then(res1 => {
									if (res1.length != 0) {
										item.issubordinate = true;
									}
								})
							})).then(res => {
								resolve1()
							})
						})
					});
					
					let getUser = new Promise((resolve1, reject) => {
						this.GetOrgUsers(tree.id).then(list => {
							resultList = resultList.concat(list);
						}).finally(()=>{
							resolve1()
						})
					});

					Promise.all([getTeam, getUser]).then(res => {
						console.log('resultList',resultList);
						resolve(resultList);
					});
				}, 1000)
			},
			prevClick(val) {
				this.listQuery.PageIndex = val
				this.OrganizationList()
			},
			//删除团队下的员工
			DeleteStaff(row, index) {

				this.$confirm('确定删除该员工?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.StaffQuery.org_ids.ids = [row.orgids]
					// this.StaffQuery.account_id=row.id
					console.log(this.StaffQuery)
					this.API.OrganizationRemoveOrgUsers({
						ids: row.orgids
					}, {
						account_id: row.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success("操作成功")
							this.OrganizationList()
						}
					})
				}).catch(() => {

				})
			},
			changeEmployees(row){
				this.$Dialog.selectEmployees({org_ids:row.id}).then(res=>{
					this.OrganizationList()
				})
			}
		},
	};
</script>

<style lang="scss">
	.color- {
		color: #8cc5ff;
	}

	.color-box {
		color: #fff;
		background-color: #409eff;
		border-color: #409eff;
	}

	.total {
		width: 100%;
	}

	.TopLabel {
		widows: 200px;
	}

	.TopLabel_1 {
		widows: 300px;
	}

	.configure {
		width: 100%;
		background-color: #fff;

		padding: 30px 10px 10px 10px;
	}
</style>
